<template>
  <transition :name="from">
    <div
      v-if="show"
      class="overlay-backdrop"
      @click="$emit('close')"
    >
      <div
        class="drawer-panel"
        :class="{
          '-right-0 mr-auto': fromRight,
          '-left-0 ml-auto': !fromRight
        }"
        @click.stop="() => true"
      >
        <div class="menu-wrapper">
          <div class="menu-header" @click="$emit('close')">
            <img src="@/assets/images/arrow-left-long.svg" width="15" height="auto" alt="arrow left"/>
            <h4>Volver</h4>
          </div>
          <ul class="menu-top">
            <li id="user-info" class="auth-container" v-if="userLoginOK === true">
                <a :href="$baseUrl + '/app_users/' + userSlug" id="nav-profile">
                  <img :src="profilePic" />
                  <div class="profile-action">
                    <span class="name">{{name}}</span>
                    <span class="text">Mi cuenta</span>
                  </div>
                </a>
            </li>
            <li class="solutions">
              Soluciones
            </li>
            <li class="solutions-item">
              <a href="/#/shop/seller_plans" class="item" @click="$emit('close')">Planes para vendedores</a>
            </li>
            <li class="solutions-item last">
              <a href="/#/shop/account_verification" class="item" @click="$emit('close')">Cuenta verificada</a>
            </li>
            <li class="solutions">
              Ayuda y Ajustes
            </li>
            <li class="solutions-item">
              <a href="/#/faq" class="item" @click="$emit('close')">Preguntas frecuentes</a>
            </li>
            <li class="solutions-item">
              <a href="/#/contact" class="item" @click="$emit('close')">Contáctanos</a>
            </li>
            <li class="solutions-item">
              <a href="/#/terms" class="item" @click="$emit('close')">Terminos y condiciones</a>
            </li>
            <li class="solutions-item">
              <a href="https://corotosdo.zendesk.com/hc/es-419" class="item" @click="$emit('close')">¿Necesitas ayuda?</a>
            </li>
            <li class="solutions-item auth" v-if="userLoginOK === true">
              <span class="item" @click="$emit('logoutUser')">Cerrar sesión</span>
            </li>
            <li class="solutions-item auth" v-if="userLoginOK === false">
              <a class="item" @click="$emit('saveCurrentPath')" :href="signInLocation">Iniciar sesión</a>
            </li>
          </ul>
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
    export default {
      name: 'SideDrawerModal',
      props: {
        show: {
          type: Boolean,
          default: false
        },
        from: {
          default: 'slide-right',
          validator: value =>
            ['slide-right', 'slide-left'].includes(value)
        },
        userLoginOK: {
          type: Boolean,
          default: false
        },
        name: {
          type: String,
          default: ''
        },
        profilePic: {
          type: String,
          default: ''
        },
        signInLocation: {
          type: String,
          default: ''
        },
        userSlug: {
          type: String,
          default: ''
        }
      },
      computed: {
        fromRight () {
          return this.from === 'slide-right'
        }
      }
    }
</script>

<style lang="css">
  .overlay-backdrop {
    --tw-bg-opacity: 0.2;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    width: 100%;
    height: 100%;
    z-index: 20;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: fixed;
  }
  .overlay-backdrop .drawer-panel {
    width: 20rem;
    height: 100%;
    position: fixed;
    overflow: auto;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    left: 0;
  }
  .drawer-panel .menu-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .drawer-panel .menu-wrapper .menu-header {
    width: 100%;
    padding: 20px 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D0D0D0;
  }
  .drawer-panel .menu-wrapper .menu-header h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #3F69A5;
    margin: 0;
    margin-left: 7px;
  }
  .drawer-panel .menu-wrapper .menu-top {
    margin: 0;
    padding: 0 0 16px;
    list-style: none;
  }

  .drawer-panel .menu-wrapper .menu-top .auth-container {
    margin-bottom: 16px;
    padding: 16px 16px 0;
  }

  .drawer-panel .menu-wrapper .menu-top #user-info #nav-profile {
    display: flex;
    align-items: center;
  }

  .drawer-panel .menu-wrapper .menu-top #user-info #nav-profile img {
    width: 56px;
    height: 56px;
    margin-right: 12px;
    border-radius: 100%;
  }

  .drawer-panel .menu-wrapper .menu-top #user-info #nav-profile .profile-action {
    display: flex;
    flex-direction: column;
  }

  .drawer-panel .menu-wrapper .menu-top #user-info #nav-profile span.name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #1F1F1F;
  }

  .drawer-panel .menu-wrapper .menu-top #user-info #nav-profile span.text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #3F69A5;
  }

  .drawer-panel .menu-wrapper .menu-top .solutions {
    border-bottom: 1px solid #ebebeb;
    padding: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #1F1F1F;
  }

  .drawer-panel .menu-wrapper .menu-top .solutions-item .item {
    padding: 12px 16px;
    border-bottom: 1px solid #ebebeb;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #1F1F1F;
  }

  .drawer-panel .menu-wrapper .menu-top .solutions-item.auth .item {
    font-weight: 600;
  }
  
  .drawer-panel .menu-wrapper .menu-top .solutions-item.last a {
    border-bottom: 8px solid #ebebeb;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-right-enter-active,
  .slide-right-enter-active .drawer-panel,
  .slide-left-enter-active,
  .slide-left-enter-active .drawer-panel {
    transition: all .3s ease;
  }
  .slide-right-leave-active,
  .slide-right-leave-active .drawer-panel,
  .slide-left-leave-active,
  .slide-left-leave-active .drawer-panel {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-right-enter, .slide-right-leave-to
  /* .slide-leave-active below version 2.1.8 */ {
    --tw-bg-opacity: 0;
    /* .drawer-panel {
      right: -100%;
    } */
  }

  .slide-right-enter .drawer-panel, .slide-right-leave-to .drawer-panel {
    right: -100%;
  }

  .slide-left-enter, .slide-left-leave-to
  /* .slide-leave-active below version 2.1.8 */ {
    --tw-bg-opacity: 0;
    /* .panel {
      left: -100%;
    } */
  }

  .slide-left-enter .drawer-panel, .slide-left-leave-to .drawer-panel {
    left: -100%;
  }
</style>